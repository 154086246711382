.container.guests {
    padding-left: 8px;
    padding-right: 8px;
}

.image-card {
    padding-top: 32px;
    margin: 16px auto;
    width: 300px;
    height: auto;
    border-radius: 48px;
    overflow: hidden;
    position: relative;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    

    img {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: auto;
        z-index: 0;
        position: relative;
    }

    .children p {
        position: absolute;
        font-weight: 700;
        font-style: italic;
        font-size: 16px;
        white-space: pre-wrap;
        text-align: left;
    }

    &.first p:nth-of-type(1) {
        top: 16px;
        left: 24px;
    }
    &.first p:nth-of-type(2) {
        bottom: 128px;
        right: 16px;
    }
    &.first p:nth-of-type(3) {
        bottom: 24px;
        right: 24px;
    }

    &.second p:nth-of-type(1) {
        bottom: 96px;
        right: 28px;
        text-align: center;
    }
}

.image-card:first-of-type {
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
}

.image-card:nth-of-type(2) {
    margin-top: 422px;
    position: sticky;
    top: 64px;
}

.image-card.second.wiggle {
    animation: wiggle 2s ease infinite;
}

.insta {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.dont-forget,
.a70,
.album,
.e-mail,
.instagram-profile {
    cursor: pointer;
    padding: 4px 8px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    
    max-width: fit-content;
    margin: 8px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 12px;
    position: relative;

    span {
        font-style: italic;
        font-weight: 500;
        display: inline-block;
        margin-left: 8px;
        font-size: 18px;
        padding: 2px;
        padding-bottom: 4px;
    }

    img {
        height: 24px;
        width: 24px;
    }

    a {
        &,
        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
            color: rgba(56, 70, 136, 0.85);
        }
    }

    &:hover {
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        top: 2px;
    }
}

.album,
.e-mail,
.instagram-profile {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.12);
    background: rgba(255, 255, 255, 0.55);
}

.dont-forget {
    position: sticky;
    top: 484px;
    opacity: 0.4;
    margin: 0 auto;
    pointer-events: none;

    span {
        font-size: 14px;
    }

}

.e-mail img {
    width: auto;
    height: 18px;
}

.album img {
    width: auto;
}

@keyframes wiggle {
    0%   { transform: translateY(0); }
    30%  { transform: translateY(-32px); }
    50%  { transform: translateY(0); }
    100% { transform: translateY(0); }
}

.a70 {
    position: absolute;
    right: 24px;
    bottom: 0;
}
.a70 img {
    width: 44px;
    height: 44px;
}
.a70:hover {
    top: unset;
}
.a70 button {
    span:first-child {
        font-size: 18px;
    }
    span:last-child {
        font-size: 16px;
    }
    &, &:hover, &:focus, &:active {
        border: none;
        background: none;
    }
}

.help-glass {
    padding: 16px;
    background: rgba(238, 194, 44, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    
    max-width: 300px;
    margin: 8px;

    a {
        &, &:hover, &:active, &:visited {
            text-decoration: none;
            color: rgba(56, 70, 136, 0.85);
        }
        
    }
}
