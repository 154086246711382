:root {
  --yellow: rgb(238, 194, 44);
}

.entry {
  display: block;
  margin-right: 24px;
}

.number {
  font-size: 72px;
  margin-right: 4px;
  color: #1166b1e8;
}

.text {
  font-size: 36px;
  color: rgb(17, 103, 177);
}

.container {
  text-align: left;
  max-width: 1440px;
  margin: 32px auto;
  padding: 24px 56px;
}

@media(min-width: 768px) {
  .entry {
    display: inline-block;
  }

  .container {
    text-align: center;
  }
}

.agenda {
  max-width: 760px;
  margin: 0 auto;
  z-index: 0;
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, minmax(24px, 1fr));
  grid-template-rows: repeat(6, minmax(24px, 1fr));
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.grid > div:nth-child(7) {
  color: #8b0000;
}

.grid > div:nth-child(1),
.grid > div:nth-child(2),
.grid > div:nth-child(3),
.grid > div:nth-child(4),
.grid > div:nth-child(5),
.grid > div:nth-child(6),
.grid > div:nth-child(7) {
  font-weight: 500;
}

.grid > div:nth-child(40),
.grid > div:nth-child(41),
.grid > div:nth-child(42),
.grid > div:nth-child(8) {
  opacity: 0.4;
}

.grid > div {
  color: #232323;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc((100% - 21px) / 2) 0;
}

.grid > div:nth-child(34) {
  background: radial-gradient(circle, rgba(17, 103, 177) 0%, rgba(17,103,130,1) 100%);
  color: white;
  font-weight: 500;
  border-radius: 50%;
  box-shadow: 0 0 16px 0 rgba(17, 103, 177, 0.5);
  opacity: 1;
  animation: pulse 2s ease infinite;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
}

.calendar > div:first-child {
  font-size: 24px;  
  font-weight: 400;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 16px;
}
.calendar .calendar-title .month {
  display: inline-block;
  margin-right: 8px;
  font-weight: 500;
}
.calendar .calendar-title .year {
  font-size: 18px;
  font-weight: 100;
}
.calendar {
  padding-top: 48px;
  padding-left: 32px;
  padding-right: 32px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
}
.details {
  max-width: 500px;
  margin: 0 auto;
}
.details .content {
  padding: 16px;
  padding-bottom: 0;
  background: radial-gradient(circle at 100%, rgba(238, 194, 44, 0.3) 0%, rgba(238, 194, 44, 0.2) 100%);
  z-index: 1;
  position: relative;
  margin: 0;
  margin-top: 8px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.content .handle {
  width: 100px;
  position: absolute;
  height: 4px;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(128, 128, 128, 0.1);
  right: 0;
  top: 8px;
  border-radius: 4px;
  margin: 0 auto;
}
.content .title {
  font-size: 18px;
  color: #384688;
  z-index: 10;
  padding: 32px 8px;
}
.title strong {
  background: white;
  padding: 4px 16px;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.content .title {
  position: sticky;
  top: 24px;
}
.timeline {
  position: sticky;
  padding: 16px;
  padding-bottom: 0;
  top: 88px;
}
.timeline-item {
  display: flex;
  min-height: 32px;
  position: sticky;
  top: 88px;
  color: #384688;
  font-size: 18px;
}

.timeline-item:first-child {
  opacity: 0.5;
}

.timeline-item:first-child .dot::before {
  width: 10px;
  height: 10px;
  border-width: 1px;
}
.timeline-item:first-child .dot::after {
  background-color: transparent;
  border-left: 2px dotted;
  border-right-width: 0;
}

.time {
  width: 44px;
}
.dot {
  width: 20px;
  height: 20px;
  margin: 2px 16px;
  border-radius: 50%;
  background: #384688;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
}

.dot::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: #384688;
  left: 69px;
}
.dot::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  border: 2px solid white;
  border-radius: 50%;
  top: 6px;
  left: 64px;
  z-index: 1;
}

.item-title {
  color: #384688;
  max-width: 156px;
}

.calendar-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.lang-selector {
  position: fixed;
  top: 4px;
  right: 8px;
  transform: scale(1.2);
  z-index: 1;
}
.lang-selector.hidden {
  visibility: hidden;
}

.lang-selector button {
  padding: 4px;
  width: 32px;
  height: 32px;
  border: none;
  background: none;
}
.lang-selector button:not(.active) {
  opacity: 0.5;
}

.invitation {
  max-width: 760px;
  padding: 16px;
  padding-top: 64px;
  margin: 0 auto;
}

.invitation div {
  text-align: center;
  letter-spacing: 1px;
  font-style: italic;
  color: #866519;
  font-weight: 400;
}

.invitation .img img {
  width: 100%;
  max-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  position: absolute;
}
.invitation .img {
  min-height: 420px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invitation .text-container {
  position: relative;
}
.invitation .top,
.invitation .bottom,
.invitation .img {
  margin-bottom: 16px;
}

.mt {
  margin-top: 16px;
}

.top2 {
  margin-top: 32px;
}

.invitation .img2 img {
  opacity: 0.3;
}

.invitation .brackets {
  margin-top: 8px;
  margin-bottom: 8px;
  opacity: 0.8;
}

.img .text-container {
  font-size: 18px;
}

.img2 .text-container {
  font-size: 12px;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.menu-overlay {
  padding: 16px;
  background: radial-gradient(circle at 100%, rgba(238, 194, 44, 0.3) 0%, rgba(238, 194, 44, 0.2) 100%);
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  border-radius: 8px;
  position: fixed;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  backdrop-filter: blur(8px);
  z-index: 1;
  transition: all 0.2s ease;
}
.menu-overlay.closed {
  transform: translateY(-100%);
}
.menu-overlay.open {
  transform: translateY(0);
}

.menu-icon {
  z-index: 2;
}


.today + nav ul,
.menu-overlay ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 92px;
}

.today + nav ul {
  margin-top: 64px;
}

.today + nav li,
.menu-overlay li {
  display: block;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 8px 16px;
  margin: 24px auto;
  border-radius: 32px;
  background-color: white;
  max-width: 320px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.today + nav a,
.today + nav a:visited,
.menu-overlay a,
.menu-overlay a:visited
.menu-overlay a {
  display: block;
  color: #384688;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

.menu-overlay li.active {
  pointer-events: none;
  border-top-width: 0;
  background-color: rgba(56, 70, 136, 0.85);
}
li.active a {
  color: white;
}

.menu-overlay button.close {
  border: none;
  padding: 0;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  overflow: hidden;
  background: transparent;
}

@media screen and (min-width: 420px) {
  .invitation .img2 img {
    opacity: 1;
  }

  .lang-selector {
    transform: scale(1.0);
  }
}

@media screen and (min-width: 768px) {
  .menu-overlay {
    top: 24px;
    left: 24px;
    bottom: unset;
    right: 24px;
  }
  .menu-overlay.closed {
    top: 0;
  }
  .menu-overlay.open {
    top: 56px;
    left: 64px;
    bottom: unset;
    right: 64px;
  }  
}

.cntdwn {
  max-width: 500px;
  margin: 0 auto;
}

.cntdwn .today {
  display: inline-block;
  margin-top: 48px;
  font-size: 36px;
  color: #1166b1e8;
}